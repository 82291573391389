<script lang="ts">
  import type { CardHero } from '$lib/contentful/models/card-hero';
  import Text from '$lib/components/text/text.svelte';
  import Card from '$lib/components/card/card.svelte';
  import Grid from '../../layout/grid/grid.svelte';
  import Box from '../../layout/grid/box.svelte';
  import Button from '$lib/components/button/button.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import { toKebabCase } from '$lib/utilities/to-kebab-case';

  export let content: CardHero | undefined;

  const cardColumnClass = [
    '',
    'grid-cols-1',
    'max-md:grid-cols-1 xl:grid-cols-2',
    'max-md:grid-cols-1 xl:grid-cols-3',
    'max-md:grid-cols-1 max-xl:grid-cols-2 xl:grid-cols-4',
    'max-md:grid-cols-1 xl:grid-cols-3',
    'max-md:grid-cols-1 max-xl:grid-cols-2 xl:grid-cols-3',
  ];
</script>

{#if content}
  {@const {
    background,
    cards,
    heading = '',
    body,
    callsToAction,
    entityId,
    eyebrow,
    anchor,
  } = content}
  <Grid
    layout="columns"
    as="section"
    {background}
    id={anchor ?? toKebabCase(heading)}
  >
    <Box as="div" class="flex flex-col gap-10">
      <div class="flex gap-10 justify-between max-lg:flex-col lg:flex-row">
        <div class="flex flex-col w-full lg:w-2/3">
          {#if eyebrow}
            <Text
              class="text-mint"
              variant="eyebrow-16"
              {entityId}
              fieldId="eyebrow"
            >
              {eyebrow}
            </Text>
          {/if}
          {#if heading}
            <Text variant="display-68" {entityId} fieldId="heading">
              {heading}
            </Text>
          {/if}
          <RichText theme="white" text={body} fieldId="body" {entityId} />
        </div>

        <div class="flex flex-row items-center">
          {#each callsToAction as { text, ...rest }}
            <Button {...rest}>{text}</Button>
          {/each}
        </div>
      </div>

      <div class="card-grid {cardColumnClass[cards.length]}">
        {#each cards as card}
          <Card content={card} />
        {/each}
      </div>
    </Box>
    <slot />
  </Grid>
{/if}

<style lang="postcss">
  .card-grid {
    @apply grid gap-4;
  }
</style>
